// Logos on home page and Specialties page
export const ADD_SPECIALTIES = 'ADD_SPECIALTIES'
export const SPECIALTIES_FAILED = 'SPECIALTIES_FAILED'
export const SPECIALTIES_LOADING = 'SPECIALTIES_LOADING'

// banner images on Portfolio page
export const ADD_PORTFOLIO = 'ADD_PORTFOLIO'
export const PORTFOLIO_FAILED = 'PORTFOLIO_FAILED'
export const PORTFOLIO_LOADING = 'PORTFOLIO_LOADING'

// banners images on Education page
export const ADD_EDUCATION = 'ADD_EDUCATION'
export const EDUCATION_FAILED = 'EDUCATION_FAILED'
export const EDUCATION_LOADING = 'EDUCATION_LOADING'

// LinkedIn profile pics for recommendation cards on Clients page
export const ADD_RECOMMENDATIONS = 'ADD_RECOMMENDATIONS'
export const RECOMMENDATIONS_FAILED = 'RECOMMENDATIONS_FAILED'
export const RECOMMENDATIONS_LOADING = 'RECOMMENDATIONS_LOADING'

// image collection for Portfolio detail page
export const ADD_PROJECT_IMAGES = 'ADD_PROJECT_IMAGES'
export const PROJECT_IMAGES_FAILED = 'PROJECT_IMAGES_FAILED'
export const PROJECT_IMAGES_LOADING = 'PROJECT_IMAGES_LOADING'

// company logos on Clients page
export const ADD_CLIENTS = 'ADD_CLIENTS'
export const CLIENTS_FAILED = 'CLIENTS_FAILED'
export const CLIENTS_LOADING = 'CLIENTS_LOADING'

// for tracking whether page entry animations have rendered.
export const SET_LOGOCUBE_STATE = 'SET_LOGOCUBE_STATE'
export const SET_ABOUTPAGE_STATE = 'SET_ABOUTPAGE_STATE'
export const SET_HOMEPAGE_STATE = 'SET_HOMEPAGE_STATE'
export const SET_CLIENTGRID_STATE = 'SET_CLIENTGRID_STATE'
export const SET_COURSESGRID_STATE = 'SET_COURSESGRID_STATE'
